import './index.scss'

const Footer = () => {
  return (
    <footer>
      <p className="footer-title">Coded by Nicholas Defex</p>
    </footer>
  )
}

export default Footer
